/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

 import { Reducer } from 'redux';

import {
    HomePageAction,
    HomePageActionType,
    HomePageStore
} from "./HomePage.type"

/** @namespace Store/HomePage/Reducer/getInitialState */
export const getInitialState = (): HomePageStore => ({
    isLoading: false,
    config: {},
    categories: []
});

/** @namespace Store/HomePage/Reducer/HomePageReducer */
export const HomePageReducer : Reducer<HomePageStore, HomePageAction> = (
    state = getInitialState(),
    action,
) => {
    const { type } = action;

    switch (type) {
        case HomePageActionType.IS_LOADING:
            const { isLoading } = action;
            return {
                ...state,
                isLoading
            };
        case HomePageActionType.GET_CONFIG:
            const { config } = action;
            return {
                ...state,
                config
            };
        case HomePageActionType.GET_CATEGORIES:
            const { categories } = action;
            return {
                ...state,
                categories
            };
        default:
            return state;
    }
};

export default HomePageReducer;
