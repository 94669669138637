/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

 import { Reducer } from 'redux';

import {
    BannersAction,
    BannersActionType,
    BannersStore
} from "./Banners.type"

/** @namespace Store/Banners/Reducer/getInitialState */
export const getInitialState = (): BannersStore => ({
    isLoading: false,
    mainBanner: null
});

/** @namespace Store/Banners/Reducer/BannersReducer */
export const BannersReducer : Reducer<BannersStore, BannersAction> = (
    state = getInitialState(),
    action,
) => {
    const { type } = action;

    switch (type) {
        case BannersActionType.ADD_MAIN_BANNER:
            const { mainBanner } = action;
            return {
                ...state,
                mainBanner,
            };
        default:
            return state;
    }
};

export default BannersReducer;
