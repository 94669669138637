/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */

import { CityArgs, McOptions , ZipArgs } from "./IoCheckout.type";
import { Field, Query } from '@tilework/opus';


export class IoCheckoutQuery {

    // Get States
    getStates():Query<'mcStates', McOptions> {
        return new Query<'mcStates', McOptions>('mcStates')
            .addFieldList(this._getOptionsFields())
    }

    // Get Cities
    getCities(params: CityArgs): Query<'mcCities', McOptions> {
        return new Query<'mcCities', McOptions>('mcCities')
            .addArgument('state', 'String!', params.state)
            .addFieldList(this._getOptionsFields())
    }

    // Get Zips
    getZips(params: ZipArgs): Query<'mcZip', McOptions> {
        return new Query<'mcZip', McOptions>('mcZip')
            .addArgument('city', 'String!', params.city)
            .addFieldList(this._getOptionsFields())
    }


    _getOptionsFields(): Array<
        Field<'text', String>
        | Field<'value', String>
    > {
        return [
            new Field<'text', String>(`text`),
            new Field<'value', String>(`value`)
        ]
    }

}

export default new IoCheckoutQuery();