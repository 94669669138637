/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */

import { AnyAction } from 'redux';

export enum HomePageActionType {
    IS_LOADING = 'IS_LOADING',
    GET_CONFIG = 'GET_CONFIG',
    GET_CATEGORIES = 'GET_CATEGORIES'
}

export interface HomePageStore {
    isLoading: boolean;
    config: HomePageConfig|{}
    categories: any[];
}

export interface HomePageConfig {
    homepage_category: String
    homepage_after_category_block_display: HomePageCategoryBlock[]
}

export interface HomePageCategoryBlock {
    category: String
    block: String
}

// ACTIONS
export interface isLoadingAction extends AnyAction {
    type: HomePageActionType.IS_LOADING;
    isLoading: boolean;
}

export interface getCategoriesAction extends AnyAction {
    type: HomePageActionType.GET_CATEGORIES;
    Categories: String[];
}

export interface getConfigAction extends AnyAction {
    type: HomePageActionType.GET_CONFIG;
    config: HomePageConfig;
}



export type HomePageAction = getCategoriesAction | getConfigAction | isLoadingAction