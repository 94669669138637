/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */

import { AnyAction } from 'redux';

export enum MenuActionType {
    IS_LOADING = 'IS_LOADING',
    GET_DROP_DOWN_CATEGORY = 'GET_DROP_DOWN_CATEGORY',
    GET_DROP_DOWN_CATEGORIES = 'GET_DROP_DOWN_CATEGORIES'
}

export interface MenuStore {
    isLoading: boolean;
    dropdown_category: string,
    dropdown_categories: object
}

// ACTIONS
export interface isLoadingAction extends AnyAction {
    type: MenuActionType.IS_LOADING;
    isLoading: boolean;
}

export interface getDropDownCategory extends AnyAction {
    type: MenuActionType.GET_DROP_DOWN_CATEGORY;
    category: string;
}

export interface getDropDownCategories extends AnyAction {
    type: MenuActionType.GET_DROP_DOWN_CATEGORIES;
    dropdown_categories: object;
}



export type MenuAction = getDropDownCategory | isLoadingAction | getDropDownCategories