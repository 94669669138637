/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */
import { AnyAction } from 'redux';

export interface McOptions {
    text: String
    value: String
}

export interface CityDataArgs {
    state : String
}


export interface ZipDataArgs {
    city : String
}

export enum ActionType {
    SET_CITY = 'SET_CITY',
    SET_STATE = 'SET_STATE',
    SET_ZIP = 'SET_ZIP'
}

export interface setCityDataAction extends AnyAction {
    type: ActionType.SET_CITY;
    payload: McOptions;
}

export interface setZipDataAction extends AnyAction {
    type: ActionType.SET_ZIP;
    payload: McOptions;
}

export type CityDataAction = setCityDataAction
    | setZipDataAction