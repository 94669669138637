/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */

import { fetchQuery } from "@scandipwa/scandipwa/src/util/Request/Query";
import IoCheckoutQuery from '../../query/IoCheckout/IoCheckout.query';



export const addWindowHook = () => {

  var ioCheckoutStore = null
  var cartGlobal = null
  var triggerCartFun = null
  var ioCheckoutGlobal = null
  var addLoadingGlobal = null
  var removeLoadingGlobal = null


  // tip de documentor start
  var field_tipo_documento = null
  // tip de documento end


  const manageSampleGatewayPaymentMethod = () => {
    try {
      const interval = setInterval(() => {

        const sampleGateWayElement = ioCheckoutGlobal?.querySelector("#sample_gateway")

        if (sampleGateWayElement) {

          sampleGateWayElement.addEventListener("click", async () => {


            await ioCheckoutStore.commit("magentogq/setIsDefaultPlaceOrder", true);

            await ioCheckoutStore.commit(
              "magentogq/setPaymentMethodCode",
              "sample_gateway"
            );

            await ioCheckoutStore.dispatch("magentogq/setPaymentMathodOnCartAction", {
              payment_method: {
                code: "sample_gateway",
              },
            });
          })


          clearInterval(interval)
        }

      }, [1000])

    } catch (error) {
      console.log(error);
    }
  };


  const handleInitAddressFields = (props) => {
    var departmentKey = null
    var cityKey = null
    var postcodeKey = null

    var { fields, addLoading, removeLoading, renderComponentFields } = props

    // Country field manage
    fields.forEach((field, key) => {
      if (field.id == "country_id") {
        field.type = "hidden"
      }

      if (field.id == "street1") {
        field.type = "hidden"
      }

      if (field.id == "city" || field.id == "postcode" || field.id == "departments") {
        field.isLabelShow = true
        field.isSearch = true
        field.front_type = "dropdown"
        field.component = "SelectOption"
        field.data_type = "select"
        field.type = "select"
      }

      if (field.id == "city") {
        field.zIndex = 5
        field.select_options = []
        cityKey = key
      }
      if (field.id == "postcode") {
        field.zIndex = 4
        field.select_options = []
        field.required = true
        postcodeKey = key
      }
      if (field.id == "departments") {
        field.zIndex = 6
        departmentKey = key
      }


      // tip de documentor start
      if (field.id == "field_tipo_documento") {
        field_tipo_documento = field

        const typoFields = ["dni","ruc","ce"]
        
        fields.forEach((value, key) => {
            if(typoFields.includes(value.id)){
              value.type = "hidden"
            }
            const typoField = field.select_options.find((item) => item.value == field.value)
            if(value.id == typoField?.text?.toLowerCase()){
              value.type = "text"
            }
        })

        if(!field.value){
          field.value = field.select_options[1]?.value
          const typoField = field.select_options.find((item) => item.value == field.value)
          fields.forEach((value, key) => {
            if(typoField?.text?.toLowerCase() == value.id){
              value.type = "text"
            }
          })
        }

      }

      
      // tip de documento end


      fields[key] = field
    })

    try {
      addLoading()

      if (departmentKey != null) {

        // Departments
        fetchQuery(IoCheckoutQuery.getStates()).then((response) => {
          const { mcStates } = response
          fields[departmentKey].select_options = mcStates

          // City
          if (cityKey != null && fields[departmentKey].value != null && fields[departmentKey].value != "") {
            fetchQuery(IoCheckoutQuery.getCities({ state: fields[departmentKey].value })).then((response) => {
              const { mcCities } = response
              fields[cityKey].select_options = mcCities

              // Postcode
              if (postcodeKey != null && fields[cityKey].value != null && fields[cityKey].value != "") {
                fetchQuery(IoCheckoutQuery.getZips({ city: fields[cityKey].value })).then((response) => {
                  const { mcZip } = response
                  fields[postcodeKey].select_options = mcZip
                  renderComponentFields(fields)
                  removeLoading()
                })

              } else {
                renderComponentFields(fields)
                removeLoading()
              }

            })
          } else {
            if (mcStates.length) {
              fields[departmentKey].value = mcStates[0].value
              fetchQuery(IoCheckoutQuery.getCities({ state: mcStates[0].value })).then((response) => {
                const { mcCities } = response
                fields[cityKey].select_options = mcCities
                fields[cityKey].value = mcCities[0].value
                // Postcode
                if (mcCities.length) {
                  fetchQuery(IoCheckoutQuery.getZips({ city: mcCities[0].value })).then((response) => {
                    const { mcZip } = response
                    fields[postcodeKey].select_options = mcZip
                    fields[postcodeKey].value = mcZip[0].value
                    renderComponentFields(fields)
                    removeLoading()
                  })

                } else {
                  renderComponentFields(fields)
                  removeLoading()
                }

              })
            } else {
              renderComponentFields(fields)
              removeLoading()
            }

          }

        })
      }

    } catch (e) {
      removeLoading()
    }

  }

  const handleChangeAddressFields = (props) => {
    var departmentKey = null
    var cityKey = null
    var postcodeKey = null

    var { fields, addLoading, removeLoading, renderComponentFields } = props

    // Country field manage
    fields.forEach((field, key) => {
      if (field.id == "country_id") {
        field.type = "hidden"
      }

      if (field.id == "street1") {
        field.type = "hidden"
      }

      if (field.id == "city" || field.id == "postcode" || field.id == "departments") {
        field.isLabelShow = true
        field.isSearch = true
        field.front_type = "dropdown"
        field.component = "SelectOption"
        field.data_type = "select"
        field.type = "select"
      }

      if (field.id == "city") {
        field.zIndex = 5
        field.select_options = []
        cityKey = key
      }
      if (field.id == "postcode") {
        field.zIndex = 4
        field.select_options = []
        field.required = true
        postcodeKey = key
      }
      if (field.id == "departments") {
        field.zIndex = 6
        departmentKey = key
      }

      fields[key] = field
    })

    try {
      addLoading()

      if (departmentKey != null) {

        // Departments
        fetchQuery(IoCheckoutQuery.getStates()).then((response) => {
          const { mcStates } = response
          fields[departmentKey].select_options = mcStates

          // City
          if (cityKey != null && fields[departmentKey].value != null && fields[departmentKey].value != "") {
            fetchQuery(IoCheckoutQuery.getCities({ state: fields[departmentKey].value })).then((response) => {
              const { mcCities } = response
              fields[cityKey].select_options = mcCities

              // Postcode
              if (postcodeKey != null && fields[cityKey].value != null && fields[cityKey].value != "") {
                fetchQuery(IoCheckoutQuery.getZips({ city: fields[cityKey].value })).then((response) => {
                  const { mcZip } = response
                  fields[postcodeKey].select_options = mcZip
                  renderComponentFields(fields)
                  removeLoading()
                })

              } else {
                renderComponentFields(fields)
                removeLoading()
              }

            })
          } else {
            renderComponentFields(fields)
            removeLoading()
          }

        })
      }

    } catch (e) {
      removeLoading()
    }

  }


  window.IoCheckoutHooks = {

    beforeCheckoutInit: async function () {
      console.log("before checkout intit")
      manageSampleGatewayPaymentMethod()
    },

    renderInitAddressFields: async function (props) {
      console.log(props, "renderInitAddressFields")

      const { fields } = props

      handleInitAddressFields(props)
      return fields
    },

    renderChangeAddressFields: async function (props) {

      console.log(props, "renderChangeAddressFields")

      var cityKey = null
      var postcodeKey = null

      var { fields, changableField, addLoading, removeLoading, renderComponentFields } = props



      // tipo de documento start
      if (changableField.id == "field_tipo_documento") {
        field_tipo_documento = changableField
        const typoFields = ["dni","ruc","ce"]
        fields.forEach((value, key) => {
            if(typoFields.includes(value.id)){
              value.type = "hidden"
              value.value = ""
            }

            const typoField = changableField.select_options.find((item) => item.value == changableField.value)
            console.log(value.select_options);
            console.log(changableField);
            console.log(typoField,"typoField");
            if(value.id == typoField.text.toLowerCase()){
              value.type = "text"
            }

        })
      }
      // tipo de documento end


      if (changableField.id == "departments" || changableField.id == "city") {

        addLoading()

        fields.forEach((value, key) => {
          if (value.id == "city" && changableField.id == "departments") {
            value.value = ""
            cityKey = key
          }
          if (value.id == "postcode" && changableField.id == "city") {
            value.value = ""
            postcodeKey = key
          }
        })

        // Manage city field
        if (cityKey) {

          try {

            let stateValue = null
            if (changableField.id == "departments") {
              changableField.select_options.forEach((option) => {
                if (option.value == changableField.value) {
                  stateValue = option.text
                }
              })
            }

            if (stateValue != null) {
              try {
                fetchQuery(IoCheckoutQuery.getCities({ state: stateValue })).then(async (response) => {
                  const { mcCities } = response
                  fields[cityKey].select_options = mcCities

                  await renderComponentFields(fields)
                  await removeLoading()
                })
              } catch (e) {
                removeLoading()
              }
            } else {
              removeLoading()
            }
          } catch (e) {
            removeLoading()
          }
        }

        // Manage postcode field
        if (postcodeKey) {
          try {
            fetchQuery(IoCheckoutQuery.getZips({ city: changableField.value })).then(async (response) => {
              const { mcZip } = response
              fields[postcodeKey].select_options = mcZip
              await renderComponentFields(fields)
              await removeLoading()
            })
          } catch (e) {
            removeLoading()
          }
        }


        renderComponentFields(fields)
      }

      if (changableField.id == "sameAsShippingAddress" && !changableField.value) {
        handleChangeAddressFields(props)
      }

    },

    renderFieldValidation: (address) => {

      if (address.id === "dni") {
        const idValue = address.value;
        const isNumeric = /^\d+$/.test(idValue);
        const isValidLength = idValue.length === 8;

        if (!idValue) {
          return {
            validate: false,
            validateMessage: "Se requiere identificación."
          };
        } else if (!isNumeric) {
          return {
            validate: false,
            validateMessage: "La identificación debe contener solo dígitos."
          };
        } else if (!isValidLength) {
          return {
            validate: false,
            validateMessage: "La identificación debe tener exactamente 8 dígitos."
          };
        }
        // If validation passes
        return {
          validate: true,
          validateMessage: ""
        };
      }

      if (address.id === "ruc") {
        const idValue = address.value;
        const isNumeric = /^\d+$/.test(idValue);
        const isValidLength = idValue.length === 11;

        if (!idValue) {
          return {
            validate: false,
            validateMessage: "Se requiere identificación."
          };
        } else if (!isNumeric) {
          return {
            validate: false,
            validateMessage: "La identificación debe contener solo dígitos."
          };
        } else if (!isValidLength) {
          return {
            validate: false,
            validateMessage: "La identificación debe tener exactamente 8 dígitos."
          };
        }
        // If validation passes
        return {
          validate: true,
          validateMessage: ""
        };
      }

      if (address.id === "ce") {
        const idValue = address.value;
        const isNumeric = /^\d+$/.test(idValue);
        const isValidLength = idValue.length === 9;

        if (!idValue) {
          return {
            validate: false,
            validateMessage: "Se requiere identificación."
          };
        } else if (!isNumeric) {
          return {
            validate: false,
            validateMessage: "La identificación debe contener solo dígitos."
          };
        } else if (!isValidLength) {
          return {
            validate: false,
            validateMessage: "La identificación debe tener exactamente 8 dígitos."
          };
        }
        // If validation passes
        return {
          validate: true,
          validateMessage: ""
        };
      }


    },

    renderIoCheckoutJS: function (props) {
      const {
        addLoading,
        removeLoading,
        iocheckout,
        triggerCart,
        cart,
        ioCheckoutElement
      } = props


      cartGlobal = cart
      triggerCartFun = triggerCart
      ioCheckoutGlobal = ioCheckoutElement
      addLoadingGlobal = addLoading
      removeLoadingGlobal = removeLoading
      ioCheckoutStore = iocheckout



    },

    afterRenderBanktransfer: function () {
      console.log("after Render Bank Transfer");
      return `<div id='sample_gateway' class='sample-gateway-method'><div class='sample-gateway-method-title'><div class='gateway-method-title-text'>YAPEANOS al finalizar la transacción al 930 171 475 a nombre de Universo Educativo SAC, luego envíanos la constancia al mismo número indicando tu número de orden de compra <span class='sample-gateway-img'><img src='https://maka.com.pe/media/wysiwyg/yape.jpg' alt='Gateway Method' width='50' height='30' style="
      min-width: 50px;" /></span></div><span class='method-border'></span></div></div>`

    },

    afterSavePaymentMethod: function (result) {
      const { method_code } = result;
      const sample_pay_element = ioCheckoutGlobal.querySelector("#sample_gateway")


      if (!sample_pay_element) {
        return
      }

      if (method_code == "sample_gateway") {
        sample_pay_element.classList.add("active")

      } else {
        sample_pay_element.classList.remove("active")
      }
    },

    afterClickPaymentMethodHook: function (result) {
      const { paymentMethodCode } = result

      if (paymentMethodCode != "sample_gateway") {
        const sample_pay_element = ioCheckoutGlobal.querySelector("#sample_gateway")
        sample_pay_element.classList.remove("active")
      }
    }

  }
}

