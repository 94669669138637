/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */

import { AnyAction } from 'redux';

export enum BannersActionType {
    ADD_MAIN_BANNER = 'ADD_MAIN_BANNER'
}

export interface addMainBannerAction extends AnyAction {
    type: BannersActionType.ADD_MAIN_BANNER;
    mainBanner: String;
}

export interface BannersStore {
    isLoading: boolean;
    mainBanner: String|null;
}

export type BannersAction = addMainBannerAction