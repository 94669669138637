/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { Reducer } from 'redux';

import {
    MenuAction,
    MenuActionType,
    MenuStore
} from "./Menu.type"

/** @namespace Store/Menu/Reducer/getInitialState */
export const getInitialState = (): MenuStore => ({
    isLoading: false,
    dropdown_category: "",
    dropdown_categories: {}
});

/** @namespace Store/Menu/Reducer/MenuReducer */
export const MenuReducer: Reducer<MenuStore, MenuAction> = (
    state = getInitialState(),
    action,
) => {
    const { type } = action;

    switch (type) {
        case MenuActionType.IS_LOADING:
            const { isLoading } = action;
            return {
                ...state,
                isLoading
            };
        case MenuActionType.GET_DROP_DOWN_CATEGORY:
            const { dropdown_category } = action;
            return {
                ...state,
                dropdown_category
            };
        case MenuActionType.GET_DROP_DOWN_CATEGORIES:
            const { dropdown_categories } = action;
            return {
                ...state,
                dropdown_categories
            };
        default:
            return state;
    }
};

export default MenuReducer;
