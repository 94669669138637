/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa-theme
 * @link https://github.com/scandipwa/scandipwa-theme
*/
// @ts-nocheck

import { Reducer } from 'redux';

import {
    HeaderActionType
} from './Header.type';

/** @namespace Store/Header/Reducer/getInitialState */
export const getInitialState = () => {

    return {
        categoryList:[]
    };
};

/** @namespace Store/Header/Reducer/CmsReducer */
export const HeaderReducer = (
    state = getInitialState(),
    action,
) => {
    
    switch (action.type) {
        case HeaderActionType.SET_CATEGORY_LIST: {
            const { categoryList = [] } = action;
    
            return {
                ...state,
                categoryList:action.categoryList,
            };
        }
        default:
            return state;
    }
};

export default HeaderReducer;
