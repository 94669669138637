/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */
/* tslint:disable */
// @ts-nocheck


export enum HeaderActionType{
    SET_CATEGORY_LIST = 'SET_CATEGORY_LIST',
}

