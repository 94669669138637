/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */


import { Reducer } from "react";
import { McOptions } from "Query/CityData.type";
import { CityDataAction, ActionType } from "./CityData.type";

export const getInitialState = (): McOptions => {
    return {
        text : "",
        value : ""
    };
};

export const CityDataReducer: Reducer<McOptions, CityDataAction> = (
    state = getInitialState(),
    action,
) => {
    switch (action.type) {

        case ActionType.SET_CITY: {

            return {
                ...state,
                cityItems: action.payload
            };
        }

        case ActionType.SET_ZIP: {

            return {
                ...state,
                zipItems: action.payload,
            }
        }


        default:
            return state;
    }
};

export default CityDataReducer;